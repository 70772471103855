<template>
  <div class="w-full grid grid-cols-2 py-2 justify-center items-center">
    <div class="w-full flex justify-start items-center flex-shrink-0">
      <NuxtLink class="h-full flex-shrink-0" to="/app" aria-label="Home">
        <button class="h-full flex justify-center items-center hover:scale-110 duration-300">
          <!-- <img class="w-14 flex-shrink-0" src="https://oxbull-presale.s3.amazonaws.com/oxb_green_header.png" /> -->
          <NuxtImg quality="50" class="w-14 object-cover" src="/oxb_green_header.png" alt="Oxbull Logo" />
        </button>
      </NuxtLink>

      <div class="flex justify-start items-center space-x-2 text-zinc-400 ml-2">
        <NuxtLink :to="item.path" v-for="item in pinnedNav">
          <button :aria-label="item.title" class="text-sm hover:bg-turquoise-500/10 hover:text-turquoise-500 py-2 px-3 rounded-xl duration-100" :class="[route.name === item.name ? 'text-white bg-white/10 ' : '']">
            {{ item.title }}
          </button>
        </NuxtLink>

        <ClientOnly>
          <HeadlessPopover class="relative">
            <HeadlessPopoverButton class="w-8 h-8 rounded-xl bg-turquoise-500/10 ui-open:bg-turquoise-500 flex justify-center items-center" aria-label="View Links">
              <!-- <span>Solutions</span> -->
              <!-- <ChevronDownIcon class="h-5 w-5" aria-hidden="true" /> -->
              <Icon name="flowbite:chevron-down-outline" class="text-turquoise-500 text-xl ui-open:text-turquoise-1000 ui-open:rotate-180 rotate-0 duration-300" />
            </HeadlessPopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <HeadlessPopoverPanel class="absolute right-0 z-10 mt-3 flex w-screen max-w-max px-4">
                <div class="w-screen max-w-sm flex-auto rounded-xl bg-zinc-900 border-zinc-800 border max-h-[80vh]">
                  <simplebar class="h-full w-full overflow-y-auto">
                    <div class="p-4 w-full">
                      <section class="mb-4">
                        <h6 class="text-white px-4 py-2 font-semibold">App</h6>

                        <NuxtLink to="/app" aria-label="Home">
                          <HeadlessPopoverButton class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100">Home</HeadlessPopoverButton>
                        </NuxtLink>

                        <NuxtLink :to="nav.path" v-for="nav in navItems" :aria-label="nav.title">
                          <HeadlessPopoverButton class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100">
                            {{ nav.title }}
                          </HeadlessPopoverButton>
                        </NuxtLink>
                      </section>

                      <section class="mb-4">
                        <h6 class="text-white px-4 py-2 font-semibold">OXB Token</h6>

                        <button @click="copy()" class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-between items-center group">
                          <span>Address: {{ OXB_V2_ADDRESS.substring(0, 10) }}...</span>

                          <div class="flex justify-center items-center space-x-2">
                            <template v-if="!copied">
                              <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copy</span>
                              <Icon name="mdi:content-copy" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                            </template>

                            <template v-else>
                              <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copied</span>
                              <Icon name="mdi:check" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                            </template>
                          </div>
                        </button>

                        <a :href="`https://pancakeswap.finance/swap?outputCurrency=${OXB_V2_ADDRESS}`" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <img class="opacity-70 w-5 h-5 p-0.5 mr-2" src="/bunny-mono.png" alt="Pancakeswap logo" />
                            Buy on PancakeSwap
                          </button>
                        </a>

                        <a href="https://coinmarketcap.com/currencies/oxbull-tech/" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70" aria-hidden="true" />
                            CoinMarketCap
                          </button>
                        </a>

                        <a href="https://www.coingecko.com/en/coins/oxbull-tech" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" /> -->
                            <img class="w-5 h-5 mr-2 opacity-70" src="/cg.svg" alt="CoinGecko logo" />
                            CoinGecko
                          </button>
                        </a>

                        <a href="https://cryptorank.io/fundraising-platforms/oxbull" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70 " aria-hidden="true" /> -->
                            <img class="w-5 h-5 mr-2 opacity-70" src="/symbol_white.png" alt="Cryptorank logo" />
                            Cryptorank
                          </button>
                        </a>

                        <a href="https://chainbroker.io/platforms/oxbull" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                            <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70 " aria-hidden="true" /> -->
                            <div class="w-5 h-5 mr-2 flex justify-center items-center">
                              <img class="w-4 h-4 opacity-70" src="/chainbroker.png" alt="Chainbroker logo" />
                            </div>
                            Chainbroker
                          </button>
                        </a>
                      </section>

                      <section class="mb-4">
                        <h6 class="text-white px-4 py-2 font-semibold">Socials</h6>

                        <a href="https://twitter.com/Oxbull5" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-1 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="prime:twitter" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            X @Oxbull5
                          </button>
                        </a>

                        <a href="https://t.me/Oxbull_tech" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-1 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            Official Group
                          </button>
                        </a>

                        <a href="https://t.me/Oxbull_tech_announcement" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-1 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            Announcement
                          </button>
                        </a>

                        <a href="https://warpcast.com/oxbulltech" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="simple-icons:farcaster" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            Farcaster @oxbulltech
                          </button>
                        </a>

                        <a href="https://www.instagram.com/oxbulltech/" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="mdi:instagram" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            Instagram @oxbulltech
                          </button>
                        </a>

                        <a href="https://www.tiktok.com/@oxbull.tech" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="ic:baseline-tiktok" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            Tiktok @oxbull.tech
                          </button>
                        </a>
                      </section>

                      <section class="mb-4">
                        <h6 class="text-white px-4 py-2 font-semibold">Read More</h6>

                        <a href="https://oxbull.medium.com/" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="mingcute:medium-fill" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            Blog on Medium
                          </button>
                        </a>

                        <a href="https://docs.oxbull.tech/" target="_blank">
                          <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 px-4 py-2 rounded-xl duration-100 flex justify-start items-center group">
                            <Icon name="simple-icons:gitbook" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                            Documentation
                          </button>
                        </a>
                      </section>
                    </div>

                    <div class="w-full sticky bottom-0 p-4">
                      <button :disabled="$userDataPulling" @click="refreshData()" class="btn m-0 h-auto min-h-0 w-full bg-zinc-800 py-3 px-4 rounded-xl flex justify-between items-center disabled:bg-zinc-700 hover:bg-zinc-800">
                        <div class="flex justify-start items-center">
                          <Icon name="mdi:refresh" class="text-turquoise-500 text-xl mr-2" />
                          <span>Refresh</span>
                        </div>

                        <Icon v-if="$userDataPulling" name="mdi:loading" class="text-turquoise-500 text-xl animate-spin" />
                      </button>
                    </div>
                  </simplebar>
                </div>
              </HeadlessPopoverPanel>
            </transition>
          </HeadlessPopover>
        </ClientOnly>
      </div>
    </div>

    <div class="flex justify-end items-center w-full pr-3">
      <ClientOnly>
        <template v-if="!isMobile">
          <LazySearchBar />
        </template>
      </ClientOnly>

      <WalletModal v-if="!$address">
        <button class="btn min-h-0 h-auto bg-turquoise-500 text-turquoise-1000 hover:text-turquoise-500 hover:bg-turquoise-1000 rounded-full p-3">Connect Wallet</button>
      </WalletModal>

      <div v-if="$address" class="bg-white/5 border border-white/10 rounded-full px-3 py-1 pr-1 flex justify-center items-center text-zinc-400">
        <div class="text-sm">{{ $address?.substring(0, 6) }}...{{ $address?.substring($address.length - 5) }}</div>

        <button @click="$deauth()" class="btn btn-circle bg-white/10 ml-2 btn-sm flex justify-center items-center rounded-full">
          <Icon name="mdi:power" class="text-turquoise-500 text-xl" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
const route = useRoute();

import { useUserStore } from "~/stores/user.js";
const userStore = useUserStore();

import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";

import { OXB_V2_ADDRESS } from "~/wagmiConfig.js";
// import { getAccount, watchConnections } from "@wagmi/core";
import { queryNoCache } from "~/apollo";
import { gql } from "@apollo/client/core";
import { useClipboard } from "@vueuse/core";

const { text, copy, copied, isSupported } = useClipboard({ source: OXB_V2_ADDRESS });

import { useEventBus } from "@vueuse/core";
import SearchBar from "../SearchBar.vue";
const bus = useEventBus("reload");

const { $accessToken, $address, $deauth, $auth, $pullUserData, $userDataPulling } = useNuxtApp();

const reauth = async () => {
  // await $deauth();
  // await $auth();
};

import { useScreens } from "vue-screen-utils";

const { mapCurrent } = useScreens({
  xs: "0px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
});

const isMobile = mapCurrent({
  xs: true,
  sm: false,
  md: false,
  lg: false,
});

onMounted(() => {
  watch($accessToken, () => {
    renderHeader();
  });
});

const address = ref("");

const showRefresh = ref(false);

// const toggleRefresh = (ev) => {
//   showRefresh.value = ev;
// };

// const refreshPage = (close) => {
//   close();
//   console.log(bus);
//   bus.emit("reload", true);
// };

const accountData = ref({});

const renderHeader = async () => {
  // accountData.value = null;
  // if (userStore.authenticated) {
  //   let gqlResult = await queryNoCache(
  //     gql`
  //       query User($limit: Int, $after: Int) {
  //         user(limit: $limit, after: $after) {
  //           tiers
  //         }
  //       }
  //     `,
  //     {
  //       limit: 1,
  //       after: null,
  //     }
  //   );
  //   accountData.value = gqlResult["user"];
  // }
};

const navItems = [
  // {
  //   name: "home",
  //   path: "/app",
  //   icon: "entypo:home",
  //   title: "Home",
  // },
  {
    name: "account",
    path: "/app/account",
    icon: "fa-solid:user-circle",
    title: "Account",
  },
  {
    name: "claim",
    path: "/app/claim",
    icon: "mdi:coins-plus",
    title: "Claim",
  },
  {
    name: "stake",
    path: "/app/stake",
    icon: "bx:bxs-bank",
    title: "Stake",
  },
  {
    name: "calendar",
    path: "/app/calendar",
    icon: "mdi:calendar",
    title: "Calendar",
  },
  {
    name: "policy",
    path: "/app/policy",
    icon: "ic:baseline-policy",
    title: "Tiers & Policy",
  },

  {
    name: "privateSalePass",
    path: "/app/private-sale-pass",
    icon: "mdi:passport",
    title: "Private Sale Pass",
  },

  {
    name: "otc",
    path: "/app/otc",
    icon: "ant-design:shop-filled",
    title: "OTC",
  },
];

const pinnedNav = computed(() => {
  let _navItems = [...navItems];
  return _navItems.splice(0, 4);
});

const refreshData = async () => {
  $pullUserData();
};

// const nonPinnedNav = computed(() => {
//   let _navItems = [...navItems];
//   return _navItems.splice(4);
// });

// const currentTitle = computed( () => {
//   return navItems.find((item) => item.name === route.name).title;
// })

// const getBadge = computed(() => {
//   switch (accountData.value?.tiers) {
//     case "BSC_T1":
//       return "https://oxbull-presale.s3.amazonaws.com/tier1.svg";

//     case "BSC_T2":
//       return "https://oxbull-presale.s3.amazonaws.com/tier2.svg";

//     case "BSC_T3":
//       return "https://oxbull-presale.s3.amazonaws.com/tier3.svg";

//     default:
//       return false;
//   }
// });

// console.log(route);

// onUnmounted(() => {
//   unwatch();
// });
</script>

<style></style>
